import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCentralInventoryData,
  GetCentralInventoryDataParams,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";

// get all
export const getAllCentralInventoryAsync = createAsyncThunk<
  GetCommonData<GetCentralInventoryData>,
  Params<GetCentralInventoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-inventory/getAllCentralInventory",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as any;

    try {
      const data = await AXIOS.get(BASE_API.getAllCentralInventory, {
        params: { ...rest },
        headers: {
          permissionid,
        },
      });

      return {
        data: data.data?.data,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get central inventory fail");
      return rejectWithValue(error);
    }
  }
);

// create
export const createCentralInventoryAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>
>(
  "admin-inventory/createCentralInventory",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createCentralInventory, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Create central inventory successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Create central inventory fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateCentralInventoryAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-inventory/updateCentralInventory",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateCentralInventory}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Central inventory update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Central inventory update fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singleCentralInventoryAsync = createAsyncThunk<any, Params<any>>(
  "admin-inventory/singleCentralInventory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.singleCentralInventory}/${params?.id}`,
        {
          headers: { permissionid: params?.permissionid },
        }
      );

      return data.data?.data as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get single central inventory fail");
      return rejectWithValue(error);
    }
  }
);
