import { createSlice } from "@reduxjs/toolkit";
import { SetupForCentralInitialStatus } from "../../../../types/redux";
import {
  createCentralInventoryAsync,
  getAllCentralInventoryAsync,
  singleCentralInventoryAsync,
  updateCentralInventoryAsync,
} from "./SetupForCentralApi";

const initialState: SetupForCentralInitialStatus = {
  loading: false,
  manageLoading: false,
  viewLoading: false,
  centralInventoryData: [],
  singleCentralInventoryData: null,
  countCentralInventoryData: 0,
};

const setupInventorySlice = createSlice({
  name: "admin-inventory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllCentralInventoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCentralInventoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.centralInventoryData = action.payload.data;
        state.countCentralInventoryData = action.payload.length;
      })
      .addCase(getAllCentralInventoryAsync.rejected, (state) => {
        state.loading = false;
        state.centralInventoryData = [];
        state.countCentralInventoryData = 0;
      });

    // create
    builder
      .addCase(createCentralInventoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createCentralInventoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createCentralInventoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // update
    builder
      .addCase(updateCentralInventoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateCentralInventoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateCentralInventoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singleCentralInventoryAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(singleCentralInventoryAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleCentralInventoryData = action.payload;
      })
      .addCase(singleCentralInventoryAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleCentralInventoryData = null;
      });
  },
});

export default setupInventorySlice;
