import { useLayoutEffect, useState } from "react";
import AXIOS from "../helpers/api";
import { AUTH_SAVE } from "../helpers/config";
import { userLoggedIn } from "../store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "../store/store";

export default function useAuthChecked() {
  const dispatch = useAppDispatch();
  const [authChecked, setAuthChecked] = useState(true);
  const { token } = useAppSelector((state) => state.login);

  if (token) {
    AXIOS.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    AXIOS.defaults.headers.common["ngrok-skip-browser-warning"] = true;
  }

  useLayoutEffect(() => {
    const localAuth = localStorage?.getItem(`${AUTH_SAVE}`);

    if (localAuth) {
      const auth = JSON.parse(localAuth);

      if (auth?.token) {
        dispatch(userLoggedIn(auth));
      } else {
        localStorage.removeItem(`${AUTH_SAVE}`);
        dispatch(userLoggedIn(null));
      }
    }

    setAuthChecked(false);
  }, [dispatch, setAuthChecked]);

  return {
    authChecked,
  };
}
