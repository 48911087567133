import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appSlice from "./features/appSlice";
import areaSlice from "./features/Area/AreaSlice";
import loginSlice from "./features/auth/loginSlice";
import bankListSlice from "./features/BankList/BankListSlice";
import bankSlice from "./features/Collateral/Bank/BankSlice";
import chequeSlice from "./features/Collateral/Cheque/ChequeSlice";
import dealerPaymentSlice from "./features/DealerPayment/DealerPaymentSlice";
import dealerSlice from "./features/Dealers/DealersSlice";
import districtSlice from "./features/District/DistrictSlice";
import divisionSlice from "./features/Division/DivisionSlice";
import giftAssignSlice from "./features/Gift/GiftAssign/GiftAssignSlice";
import GiftItemSlice from "./features/Gift/GiftItem/GiftItemSlice";
import setupFormToAnotherSlice from "./features/InventoryManagement/InventoryFromOneToAnother/InventoryFromOneToAnotherSlice";
import setupInventorySlice from "./features/InventoryManagement/SetupForCentral/SetupForCentralSlice";
import ledgerSlice from "./features/Ledger/LedgerSlice";
import notificationSlice from "./features/Notification/NotificationSlice";
import orderSlice from "./features/Order/OrderSlice";
import policeStationSlice from "./features/PoliceStation/PoliceStationSlice";
import productCategorySlice from "./features/ProductCategory/ProductCategorySlice";
import productReturnSlice from "./features/ProductReturn/ProductReturnSlice";
import productSlice from "./features/Products/ProductSlice";
import regionSlice from "./features/Region/RegionSlice";
import sbuSlice from "./features/Sbu/SbuSlice";
import appSliderSlice from "./features/Slider/AppSliderSlice";
import subCategorySlice from "./features/SubCategory/SubCategorySlice";
import territorySlice from "./features/Territory/TerritorySlice";
import unitSlice from "./features/Unit/UnitSlice";
import rolePermissionSlice from "./features/UserManage/CreateRole/RolePermissionSlice";
import createUserSlice from "./features/UserManage/CreateUser/CreateUserSlice";
import menuSlice from "./features/UserManage/Menu/MenuSlice";
import wearHouseSlice from "./features/wearHouse/WearHouseSlice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    login: loginSlice.reducer,
    sbu: sbuSlice.reducer,
    division: divisionSlice.reducer,
    district: districtSlice.reducer,
    policeStation: policeStationSlice.reducer,
    region: regionSlice.reducer,
    area: areaSlice.reducer,
    territory: territorySlice.reducer,
    category: productCategorySlice.reducer,
    subCategory: subCategorySlice.reducer,
    product: productSlice.reducer,
    unit: unitSlice.reducer,
    giftItem: GiftItemSlice.reducer,
    giftAssign: giftAssignSlice.reducer,
    order: orderSlice.reducer,
    bank: bankSlice.reducer,
    bankList: bankListSlice.reducer,
    cheque: chequeSlice.reducer,
    dealer: dealerSlice.reducer,
    wearHouse: wearHouseSlice.reducer,
    createUser: createUserSlice.reducer,
    productReturn: productReturnSlice.reducer,
    // appSettings: appSettingsSlice.reducer,
    // srManage: srManageSlice.reducer,
    // target: targetSlice.reducer,
    menu: menuSlice.reducer,
    rolePermission: rolePermissionSlice.reducer,
    slider: appSliderSlice.reducer,
    dealerPayment: dealerPaymentSlice.reducer,
    notification: notificationSlice.reducer,
    ledger: ledgerSlice.reducer,
    setupInventory: setupInventorySlice.reducer,
    setupFormToAnother: setupFormToAnotherSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
