import { createSlice } from "@reduxjs/toolkit";
import { InventoryFromInitialStatus } from "../../../../types/redux";
import {
  getInventoryDetailsLogAsync,
  getInventoryRequestAsync,
  requestAcceptInventoryAsync,
  sendRequestInventoryAsync,
} from "./InventoryFromOneToAnotherApi";

const initialState: InventoryFromInitialStatus = {
  loading: false,
  manageLoading: false,
  viewLoading: false,
  getInventoryRequestLogData: [],
  getInventoryDetailsLog: null,
  countInventoryRequestLogData: 0,
};

const setupFormToAnotherSlice = createSlice({
  name: "admin-inventory-form-to-another",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // send request
    builder
      .addCase(sendRequestInventoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(sendRequestInventoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(sendRequestInventoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getInventoryRequestAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryRequestAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getInventoryRequestLogData = action.payload.data;
        state.countInventoryRequestLogData = action.payload.length;
      })
      .addCase(getInventoryRequestAsync.rejected, (state) => {
        state.loading = false;
        state.getInventoryRequestLogData = [];
        state.countInventoryRequestLogData = 0;
      });

    // single
    builder
      .addCase(getInventoryDetailsLogAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(getInventoryDetailsLogAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.getInventoryDetailsLog = action.payload;
      })
      .addCase(getInventoryDetailsLogAsync.rejected, (state) => {
        state.viewLoading = false;
        state.getInventoryDetailsLog = null;
      });

    //  request accept
    builder
      .addCase(requestAcceptInventoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(requestAcceptInventoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(requestAcceptInventoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default setupFormToAnotherSlice;
